import { Injectable } from '@angular/core';
import { DocumentTableStateService } from './document-table-state.service';
import {
  AggregatedDocumentQuery,
  AggregatedDocumentResponse,
  DocumentServiceDocumentQuery,
  DocumentServiceHttpV1,
} from '../../../../nucleus/services/documentService/document-service.v1.http';
import { EMPTY, MonoTypeOperatorFunction, Observable, of, throwError } from 'rxjs';
import { expand, filter, map, reduce, retryWhen, switchMap, take } from 'rxjs/operators';
import { IGridResourceResponse } from '../../../../nucleus/services/models/response.model';
import { HttpErrorResponse } from '@angular/common/http';
import {
  DocumentTableService,
  JsObjectCursorResponse,
  OrderByTableQuery,
  SearchTable,
  SearchTableResponse,
  TraverseTable,
} from '@geneious/nucleus-api-client';
import { IndexStateEnum } from './document-table-state';

@Injectable({
  providedIn: 'root',
})
export class DocumentTableQueryService {
  constructor(
    private documentTableStateService: DocumentTableStateService,
    private documentTableService: DocumentTableService,
    private documentServiceHttpV1: DocumentServiceHttpV1,
  ) {}

  searchTable(
    documentID: string,
    tableName: string,
    searchOptions: SearchTable,
  ): Observable<SearchTableResponse> {
    this.documentTableStateService.selectTable(documentID, tableName);
    return this.documentTableStateService.getQueryableTable(documentID, tableName).pipe(
      take(1),
      switchMap(() =>
        this.documentTableService
          .searchTable(documentID, tableName, searchOptions)
          .pipe(this.restoreTableIfArchived(documentID, tableName)),
      ),
    );
  }

  /**
   *
   * @param documentID Document ID
   * @param tableName Table name
   * @param options Query options
   * @param includeAll When true(BEWARE), limit, size and offset option parameters will be ignored and all the data will be retrieved
   * iteratively using the query/cursor endpoints. This could be extremely slow for larger data sets. Only recommended for the cases where entire data set is required
   * such as graphs.
   * When false or undefined data will be paged honoring the limit, size and offset parameters.
   */

  queryTable(
    documentID: string,
    tableName: string,
    options?: DocumentServiceDocumentQuery,
    includeAll?: boolean,
  ): Observable<IGridResourceResponse<any>> {
    this.documentTableStateService.selectTable(documentID, tableName);
    const tableQuery$ = includeAll
      ? this.queryAll(documentID, tableName, options)
      : this.documentServiceHttpV1.queryTable(documentID, tableName, options);
    return this.documentTableStateService.getQueryableTable(documentID, tableName).pipe(
      take(1),
      switchMap(() => tableQuery$.pipe(this.restoreTableIfArchived(documentID, tableName))),
    );
  }

  /**
   * Retrieve all data iteratively
   * @param documentID Document ID
   * @param tableName Table name
   * @param options Query options
   * @private
   */
  private queryAll(
    documentID: string,
    tableName: string,
    options?: DocumentServiceDocumentQuery,
  ): Observable<IGridResourceResponse<any>> {
    let traverseTable: TraverseTable = {
      cursorSize: 10000,
      fields: options.fields ? options.fields : [],
      keepAlive: 4 * 60 * 1000,
      orderBy: options.orderBy.map((orderBy) => {
        return {
          field: orderBy.field,
          kind: orderBy.kind,
        } as OrderByTableQuery;
      }),
      where: options.where,
    };
    return this.getFirstPage(documentID, tableName, traverseTable).pipe(
      switchMap((firstPageResponse) =>
        this.getNextPages(
          documentID,
          tableName,
          firstPageResponse.metadata.paginationCursor.nextToken,
        ).pipe(map((remainingPages) => [firstPageResponse, ...remainingPages])),
      ),
      map((allResponses) => {
        const flattened = allResponses.flatMap((response) => response.data);
        return {
          data: flattened,
          metadata: {
            total: flattened.length,
            limit: flattened.length,
            offset: 0,
          },
        };
      }),
    );
  }

  private getNextPages(
    documentID: string,
    tableName: string,
    nextToken: string,
  ): Observable<JsObjectCursorResponse[]> {
    return this.getNextPage(documentID, tableName, nextToken).pipe(
      expand((currentResponse) => {
        if (currentResponse.metadata.paginationCursor.nextToken) {
          return this.getNextPage(
            documentID,
            tableName,
            currentResponse.metadata.paginationCursor.nextToken,
          );
        } else {
          return EMPTY;
        }
      }),
      reduce((responses, response) => responses.concat(response), []),
    );
  }

  private getFirstPage(
    documentID: string,
    tableName: string,
    traverseTable: TraverseTable,
  ): Observable<JsObjectCursorResponse> {
    return this.documentTableService.cursorQuery(documentID, tableName, traverseTable);
  }

  private getNextPage(
    documentID: string,
    tableName: string,
    nextToken: string,
  ): Observable<JsObjectCursorResponse> {
    if (nextToken)
      return this.documentTableService.cursorQueryNextPage(documentID, tableName, {
        token: nextToken,
      });
    else {
      return of({
        data: [],
        metadata: {
          paginationCursor: { size: 0, total: 0 },
        },
      });
    }
  }

  queryTableAggregate(
    documentID: string,
    tableName: string,
    options?: AggregatedDocumentQuery,
  ): Observable<AggregatedDocumentResponse> {
    this.documentTableStateService.selectTable(documentID, tableName);
    return this.documentTableStateService.getQueryableTable(documentID, tableName).pipe(
      take(1),
      switchMap(() =>
        this.documentServiceHttpV1
          .queryTableAggregate(documentID, tableName, options)
          .pipe(this.restoreTableIfArchived(documentID, tableName)),
      ),
    );
  }

  private restoreTableIfArchived<T>(
    documentID: string,
    tableName: string,
  ): MonoTypeOperatorFunction<T> {
    return retryWhen((attempts) =>
      attempts.pipe(
        switchMap((error: HttpErrorResponse) => {
          if (error.status === 400 && error.error?.detail?.includes('"archived" state')) {
            return this.documentTableStateService.forceRestoreTable(documentID, tableName).pipe(
              filter((state) => state.currentIndexState === IndexStateEnum.OPEN),
              take(1),
            );
          } else {
            return throwError(error);
          }
        }),
      ),
    );
  }
}
